import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const socket = io('https://api.bobmarley.icu:3001');


function App() {
  const [botStatus, setBotStatus] = useState('Stopped');
  const [botOutput, setBotOutput] = useState('');
  const [screenshot, setScreenshot] = useState(null);

  useEffect(() => {
    socket.on('botStatus', (status) => setBotStatus(status));
    socket.on('botOutput', (output) => setBotOutput((prev) => prev + output + '\n'));
    socket.on('screenshot', (img) => setScreenshot(`data:image/png;base64,${img}`));
    socket.on('loginDetected', () => setScreenshot(null));

    return () => {
      socket.off('botStatus');
      socket.off('botOutput');
      socket.off('screenshot');
      socket.off('loginDetected');
    };
  }, []);

  const startBot = () => socket.emit('startBot');
  const stopBot = () => socket.emit('stopBot');

  return (
    <div className="App">
      <h1>v1.2</h1>
      <div>
        <button onClick={startBot}>Start Bot</button>
        <button onClick={stopBot}>Stop Bot</button>
      </div>
      <div>
        status: <span style={{ color: botStatus === 'Bot started' ? 'green' : 'red' }}>{botStatus}</span>
      </div>
      
      {screenshot && (
        <div>
          <img src={screenshot} alt="Telegram Web" style={{ maxWidth: '100%' }} />
        </div>
      )}
      <div>
        <pre>{botOutput}</pre>
      </div>
    </div>
  );
}

export default App;